@import url('https://fonts.googleapis.com/css2?family=Akshar:wght@300&family=Cinzel:wght@500&family=Square+Peg&family=Updock&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    padding: 0;
}

@layer components {

    .icons {
        @apply relative z-50 flex items-center justify-center h-[45px] w-[45px] my-2 mx-2
        rounded-3xl hover:rounded-xl transition-all duration-300 ease-linear cursor-pointer;
    }

    .icon-name {
        @apply absolute w-auto p-2 m-2 min-w-max top-14
        rounded-md shadow-md
        text-white bg-black
        text-xs font-bold
        transition-all duration-100 scale-0 origin-top;
    }

    .home-cards {
        @apply p-5 bg-purple-200 w-[30%] h-[45%] rounded-lg shadow-lg shadow-gray-700 m-[10%] animate-fade-in-up;
    }

    .home-titles {
        @apply text-center mb-1;
    }

    .expression-buttons {
        @apply icons border-black border-[1px] shadow-lg shadow-gray-900 bg-fuchsia-600 h-[60px] w-[120px] text-center
        text-black hover:text-white hover:h-[80px] animate-fade-in-up m-10 font-Cinzel font-bold;
    }

}